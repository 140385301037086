<template>
  <div class="dashboard-board-list">
    <m-ratio-box class="ratio-box" :ratio="31" v-for="(item, index) in boardList" :key="index">
      <div class="board-item">
        <div class="icon">
          <div class="icon-inner" :style="{ backgroundColor: `${item.color}25` }">
            <WalletSvg class="icon-svg" :style="{color: item.color}"/>
          </div>
        </div>
        <div class="board-item-inner">
          <div class="value">
            <span class="money-text" v-if="isMoney(item.valueProp)">{{ moneyText }}</span>
            <!-- <template v-if="isMoney(item.valueProp)">{{ moneyText }}</template> -->
            <count-to
              :start-val="0"
              :decimals="2"
              :end-val="boardData[item.valueProp] || 0"
            />
          </div>
          <div class="label">{{ item.label }}</div>
        </div>
      </div>
    </m-ratio-box>
  </div>
</template>

<script>
import { boardList } from './boardData'
import CountTo from 'vue-count-to'
import { mapState } from 'vuex'
import WalletSvg from './svg/wallet.svg?inline'
import { summaryData } from '@/apiForManage/dashboard'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  components: { CountTo, WalletSvg },
  mixins: [mixRateAndMoneyMethod],
  data () {
    return {
      boardList,
      boardData: {}
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      adminUserId: (state) => state.user.adminUserId
    })
  },
  created () {
    this.getRealTimeData()
  },
  methods: {
    async getRealTimeData () {
      const { data = {} } = await summaryData()
      this.boardData = data
    }
  }
}
</script>
<style lang="less" scoped>
.dashboard-board-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  .board-item {
    user-select: none;
    height: 100%;
    display: flex;
    position: relative;
    padding: calc(16em / 14), 0;
    border-radius: @mediumRadius;
    background-color: #fff;
    box-shadow: @modelShadow;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 2px 6px 0 rgba(21, 34, 50, 0.3);
    }
    .icon {
      width: calc(75em / 14);
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-inner {
        width: calc(46em / 14);
        height: calc(46em / 14);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: calc(6em / 14);
        .icon-svg{
          font-size: calc(52em / 14);
        }
      }
    }
    .board-item-inner {
      margin: calc(-1em / 14) 0 0 0;
      flex: 1 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      padding-left: calc(1em / 14);
      .value {
        display: flex;
        align-items: flex-end;
        font-size: calc(19em / 14);
        color: #5A607F;
        font-weight: 600;
        line-height: calc(19em / 14);
        .money-text{
          font-size: calc(11em / 14);
          line-height: calc(23em / 14);
        }
      }
      .label {
        font-size: calc(13em / 14);
        font-weight: 400;
        color: #5a607f;
        line-height: calc(20em / 14);
      }
    }
  }
  .ratio-box {
    width: calc(19.6% - 7.5em / 14);
  }
}
</style>
